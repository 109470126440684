<template>
  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('team.title') }}</h2>
        <p>{{ $t('team.subtitle') }}</p>
      </div>

      <div class="row">
        <div v-for="member in dataSource" :key="member._id" class="col-lg-6 pb-3">
          <div class="member d-flex align-items-start">
            <div class="pic">
              <img
                :src="baseUrl + member.avatar"
                class="img-fluid"
                :alt="member[getTrad].fullName"
              />
            </div>
            <div class="member-info">
              <h4>{{ member[getTrad].fullName }}</h4>
              <span>{{ member[getTrad].post }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->
</template>
<script>
import apiClient from '@/services/axios'
import { ref, computed } from 'vue'

export default {
  setup() {
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const dataSource = ref([])
    apiClient
      .post('/organigrame/filter', {
        query: { status: 'active' },
      })
      .then((res) => {
        console.log(res.data)
        res.data.sort((a, b) => (a.order > b.order ? 1 : -1))
        dataSource.value = res.data
      })
      .catch((err) => console.log(err))
    return {
      dataSource,
      baseUrl,
    }
  },
  computed: {
    getTrad() {
      console.log('this.$i18n.locale', this.dataSource.en)
      if (this.$i18n.locale == 'en-US') return 'en'
      if (this.$i18n.locale == 'ar-EG') return 'ar'
      return 'fr'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
</style>