<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('menu.presentation') }}</h2>
        <p>{{ $t('presentation.subtitle') }}</p>
      </div>

      <div class="row content">
        <div class="col-lg-6 d-flex justify-content-center">
          <img src="/img/logo/logoPres.jpg" class="image" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p v-html="$t('presentation.presentation1')"></p>

          <p v-html="$t('presentation.presentation2')"></p>
          <p v-html="$t('presentation.presentation3')"></p>
          <p v-html="$t('presentation.presentation4')"></p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>
<style lang="scss" scoped>
@import '../style.scss';
.image {
  width: 80%;
}
</style>