<template>
  <!-- ======= Partenaire Section ======= -->
  <section id="partenaire" class="partenaire">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('parteners.title') }}</h2>
      </div>
      <div class="partenaire-container">
        <div class="row">
          <logo-card src="/img/partenaires/konard.png" />
          <logo-card src="/img/partenaires/nations unies.jpg" />
          <logo-card src="/img/partenaires/tajdid.png" />
          <logo-card src="/img/partenaires/emaratPolitics.png" />
          <logo-card src="/img/partenaires/tourisme.jpg" />

        </div>
      </div>
    </div>
  </section>
  <!-- End Team Partenaire -->
</template>
<script>
import LogoCard from '@/components/LogoCard'
export default {
  components: { LogoCard },
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
</style>