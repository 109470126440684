<template>
    <div>
        <div class="card" :style="style">
            <img :src="src" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'Card',
    props: {
      src: {type: String, default: ''},
      style:{type: String, default: 'background: rgb(255, 253, 253);'},
    },
}
</script>
<style lang="scss" scoped>
.card {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  float: left;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

img {
      position: absolute;
      max-width: 95%;

      // Alternate Version
      // top: 0;
      // bottom: 0;
      // right: 0;
      // left: 0;
      // margin: auto;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
.card {
  transition: transform .5s ease;
}

.card:hover {
  transform: scale(1.1);
}
</style>