<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.presentation') }}</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-12 pt-3">
        <h2 class="title pl-1">{{ $t('menu.about') }}</h2>
      </div>
    </div>

    <presentation id="presentation" />

    <features id="organisation" />

    <vocation id="vocation" />

    <team id="team" />

    <partenaire id="parteners" />
  </div>
</template>
<script>
import Presentation from './sections/Presentation'
import Features from './sections/Features'
import Vocation from './sections/Vocation'
import Team from './sections/Team'
import Partenaire from './sections/Partenaire'

import { useRoute } from 'vue-router'
import { computed, watch, onMounted, inject, ref } from 'vue'
export default {
  components: { Presentation, Features, Vocation, Team, Partenaire },
  setup() {
    const route = useRoute()
    onMounted(() => {
      let id = route.fullPath.split('#')[1]
      doScrolling(id, 1000)
    })

    watch(
      () => route.fullPath,
      (first, second) => {
        let id = route.fullPath.split('#')[1]
        console.log(id)
        if (id) doScrolling(id, 1000)
      },
    )

    function getElementY(query) {
      return window.pageYOffset + document.getElementById(query).getBoundingClientRect().top
    }

    function doScrolling(element, duration) {
      var startingY = window.pageYOffset
      var elementY = getElementY(element)
      var targetY =
        document.body.scrollHeight - elementY < window.innerHeight
          ? document.body.scrollHeight - window.innerHeight
          : elementY
      var diff = targetY - startingY
      var easing = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
      }
      var start

      if (!diff) return
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp
        var time = timestamp - start
        var percent = Math.min(time / duration, 1)
        percent = easing(percent)

        window.scrollTo(0, startingY + diff * percent)
        if (time < duration) {
          window.requestAnimationFrame(step)
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>


