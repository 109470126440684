<template>
  <!-- ======= Features Section ======= -->
  <section id="features" class="features">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('organisation.title') }}</h2>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-lg-12 mt-4 mt-lg-0">
            <div class="tab-content">
              <div class="tab-pane active show">
                <div class="row">
                  <div class="col text-center">
                    <img
                      src="/resources/images/content/organisation.png"
                      alt="Organigramme ITES"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Features Section -->
</template>
<script>
export default {
  name: 'Features',
  data: () => ({
    activeTab: 'tab1',
  }),
  methods: {
    changeTab(tab) {
      if (tab != this.activeTab) {
        this.activeTab = tab
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
.features {
  .card {
    background-color: #fff;
    padding: 10px;
    border-color: $primary;
    border-radius: 0%;
  }

  .img-fluid {
    margin: 0 auto;
  }
}
</style>
