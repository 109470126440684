<template>
    <div>
        <about-us-section></about-us-section>
    </div>
</template>
<script>
import AboutUsSection from '@/components/AboutUsSection';

export default {
    name:'AboutUs',
    components: {AboutUsSection},
}
</script>