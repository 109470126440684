<template>
    <!-- ======= Vocation Section ======= -->
    <section id="partenaire" class="partenaire">
      <div class="container">

        <div class="section-title">
          <h2>{{$t('vocation.title')}}</h2>
        </div>
        <div class="service3 icon-box-square">

    <div class="container">
         <div class="row">
        <div class="col-md-4">
          <div class="text-center">
            <span><i class="fa fa-5x fa-search"></i></span>
            <div class="services-content">
              <h2>{{$t('vocation.text1')}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <span><i class="fa fa-5x fa-users"></i></span>
            <div class="services-content">
              <h2>{{$t('vocation.text2')}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <span><i class="fa fa-5x fa-comments"></i></span>
            <div class="services-content">
              <h2>{{$t('vocation.text3')}}</h2>
            </div>
          </div>
        </div>
         </div>
    </div>
      </div>
      </div>
    </section><!-- End Team Vocation -->
</template>
<style lang="scss" scoped>
@import '../style.scss';

.service3 {
  padding: 40px 0 40px;
}

.icon-box-square .text-center {
  border: 1px solid $primary;
  padding: 30px 30px 15px;
  position: relative;
  text-align: right;
}

.icon-box-square .text-center span {
  position: absolute;
  top: -15px;
  left: -15px;
  background: #Fff;
  color: $primary;
}

.icon-box-square .text-center h2 {
  min-height: 40px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 1px;
  margin: 4px 4px 15px;
}

.icon-box-square .text-center p {
  font-size: 13px;
}


@media only screen and (min-width: 768px)  and (max-width: 992px)  {

.container {
  width: 100%;
  max-width: 750px;
}

}
</style>